import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './App.scss';

class App extends React.Component {
  state = {
    sumaLunara: 200,
    dobandaAnuala: 10,
    nrAni: 2,
    sumaTotala: 0
  };

  // 1-> 10% * sumaAnuala
  // (dobandaPrecedenta + sumaAnuala) * 10%

  onCalculate = (e) => {
    e.preventDefault();
    const { sumaLunara, dobandaAnuala, nrAni } = this.state;
    const sumaAnuala = sumaLunara * 12;
    let sumaCurenta = 0;
    const ani = [];
    let sumaTotala = 0;
    for (let i = 0; i < nrAni; i++) {
      ani[i] = i;
    }
    ani.forEach(() => {
      sumaTotala = (sumaCurenta + sumaAnuala) + (((sumaCurenta + sumaAnuala) * dobandaAnuala) / 100);
      sumaCurenta = sumaTotala;
    });
    this.setState({ sumaTotala });
  };

  render() {
    return (
      <div className="App">
        <Container>
          <Row>
            <Col>
              <Form>
                <Form.Group controlId="sumaLunara">
                  <Form.Label>Suma lunara</Form.Label>
                  <Form.Control value={this.state.sumaLunara}
                                onChange={e => this.setState({ sumaLunara: e.target.value })} type="number"
                                placeholder="Suma lunara"/>
                </Form.Group>
                <Form.Group controlId="dobandaAnuala">
                  <Form.Label>Dobanda anuala</Form.Label>
                  <Form.Control value={this.state.dobandaAnuala}
                                onChange={e => this.setState({ dobandaAnuala: e.target.value })} type="number"
                                placeholder="Dobanda anuala"/>
                </Form.Group>
                <Form.Group controlId="nrAni">
                  <Form.Label>Numar ani</Form.Label>
                  <Form.Control value={this.state.nrAni} onChange={e => this.setState({ nrAni: e.target.value })}
                                type="number"
                                placeholder="Numar ani"/>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={this.onCalculate}>
                  Calculeaza
                </Button>
              </Form>
            </Col>
            <Col><h1>{new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR'
            }).format(this.state.sumaTotala)}</h1></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default App;
